import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

interface Props {
  description?: string
  lang?: string
  meta?: {
    name: string
    content: string
  }[]
  title?: string
}

const SEO = ({ description, lang = `en`, meta = [], title }: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const pageTitle = title || site.siteMetadata?.title || 'Title'

  const metaProps = [
    {
      name: `description`,
      content: metaDescription
    },
    {
      property: `og:title`,
      content: pageTitle
    },
    {
      property: `og:description`,
      content: metaDescription
    },
    {
      property: `og:type`,
      content: `website`
    },
    {
      name: `twitter:card`,
      content: `summary`
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata?.author || ``
    },
    {
      name: `twitter:title`,
      content: pageTitle
    },
    {
      name: `twitter:description`,
      content: metaDescription
    }
  ].concat(meta)

  return <Helmet htmlAttributes={{ lang }} title={pageTitle} meta={metaProps} />
}

export default SEO
